<template>
  <div id="guardian-login">
    <TopNavigation arrowAction="/" helpAction="/help" />

    <div style="height: 60px"></div>

    <div class="guardian-login-form">
      <!-- <div class="guardian-login-form__title">
        <img
          id="guardian-login__logo"
          class="mx-auto"
          src="../../guardian/assets/images/logo-color.png"
          alt="Guardian Logo"
        />
      </div> -->
      <form autocomplete="off" @submit.prevent="userLogin">
        <!-- class="guardian-login-form__content" -->
        <div class="guardian-login-form__input-block">
          <label class="guardian-login-form__label" for="email"
            >Email address</label
          >
          <input
            v-model="login.email"
            v-on:focus="onInputFocus"
      v-on:blur="onInputBlur"
            class="guardian-login-form__input"
            placeholder="Email address"
            type="email"
            spellcheck="false"
          />
        </div>

        <div class="guardian-login-form__input-block">
          <label class="guardian-login-form__label" for="password"
            >Password</label
          >
          <div
            class="guardian-login-form__input"
            style="
              padding: 0;
              display: flex;
              align-items: center;
              overflow: hidden;
            "
          >
            <input
              v-model="login.password"
              v-on:focus="onInputFocus"
      v-on:blur="onInputBlur"
              class="guardian-login-form__input"
              style="border: none; padding: 15px; width: 100%"
              placeholder="Password"
              spellcheck="false"
              :type="type"
            />

            <svg
              v-if="!btnText"
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#01a4c0"
              style="margin: 0 10px"
              @click="showPassword"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            <svg
              v-else
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#01a4c0"
              style="margin: 0 10px"
              @click="showPassword"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          </div>
        </div>

        <button
          type="submit"
          class="guardian-login-form__button"
          style="width: 100%"
        >
          <span v-if="isBtnLoading">
            <img src="../assets/button-loader.svg" alt="" />
          </span>
          <span v-else>Log In</span>
        </button>
        <RouterLink
          replace
          to="/recover-guardian-password"
          class="guardian-login-form__forgot"
          style="text-decoration: none; font-family: var(--fredoka)"
        >
          Forgot Password?
        </RouterLink>
      </form>

      <div v-if="!isKeyboardActive" style="margin-top:" class="register-block">
        <!-- class="guardian-login-form__content" -->
        <div
          style="
            font-family: var(--sans);
            font-size: 1rem;
            text-align: center;
          "
        >
          <b>Don’t have an account yet?</b>
        </div>
        <RouterLink
          replace
          to="/register"
          class="guardian-login-form__button"
          style="margin-top: 10px; font-family: var(--fredoka); width: 100%;"
        >
          Register
        </RouterLink>
        <LoginFooter/>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import config from "../../guardian/config";
import status from "../../guardian/utils/statusCode";
import { mapActions } from "vuex";
import LoginFooter from "../../login/components/LoginFooter";
import TopNavigation from "./TopNavigation.vue";

export default {
  components: {
    LoginFooter,
    TopNavigation,
  },
  data() {
    return {
      isBtnLoading: false,
      families: [],
      isKeyboardActive: false,
      selectedFamily: null,
      selectFamily: false,
      type: "password",
      btnText: true,
      login: {
        name: "",
        password: "",
      },
    };
  },
  computed: {
    validateLogin() {
      return this.login.name && this.login.password;
    },
    validateFamily() {
      return this.selectedFamily === null;
    },
  },
  methods: {
    ...mapActions(["TOAST"]),
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = false;
      } else {
        this.type = "password";
        this.btnText = true;
      }
    },
    onInputFocus() {
      this.isKeyboardActive = true;
    },
    onInputBlur() {
      this.isKeyboardActive = false;
    },
    userLogin() {
      if (this.login.email && this.login.password) {
        const { email, password } = this.login;
        this.isBtnLoading = true;

        axios
          .post(config.API_HOST + "/login/frontend", {
            email: email,
            password: password,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              const { token, userId, start_page } = response.data.data;

              localStorage.setItem("guardian_user_id", userId);
              localStorage.setItem("guardian_token", token);
              localStorage.setItem("guardian_start_page", start_page);
              localStorage.setItem("user_type", "guardian");

              if (response.data.data.families.length > 1) {
                this.families = response.data.data.families;
              } else {
                if (response.data.data.families.length > 0) {
                  this.selectedFamily = response.data.data.families[0].id;
                } else {
                  this.selectedFamily = 0;
                }
                setTimeout(() => {
                  this.letsGo();
                }, 2000);
              }
            } else {
              this.isBtnLoading = false;
              if (
                response.data.message.includes(
                  "User is not registered in SonikPocket"
                ) &&
                response.data.statusCode === 404
              ) {
                this.TOAST(response.data.message);
              }
              if (response.data.statusCode === 406) {
                this.TOAST(response.data.message);
              }
              if (
                response.data.message.includes(
                  "Please activate or confirm your email address first"
                ) &&
                response.data.statusCode === 404
              ) {
                this.$router.replace({
                  path: "/",
                  query: { showResendCode: true, resendEmail: email },
                });
              }
            }
          })
          .catch((error) => {
            this.TOAST("Something went wrong");
            throw error;
          });
      } else {
        this.isBtnLoading = false;
      }
    },
    letsGo() {
      if (
        this.selectedFamily != "" ||
        this.selectedFamily != "undefined" ||
        this.selectedFamily != null
      ) {
        let loginUserToken = localStorage.getItem("guardian_token");
        let loginUserId = localStorage.getItem("guardian_user_id");
        let loginUserStartPage = localStorage.getItem("guardian_start_page");

        let famSelected = this.selectedFamily;
        localStorage.setItem("guardian_login_family_id", famSelected);
        localStorage.setItem("guardian_family_id", famSelected);

        if (loginUserStartPage == 0) {
          location.replace(
            "https://" +
              window.location.host +
              `/guardian?token=${loginUserToken}&userId=${loginUserId}&family_id_check=${famSelected}`
          );
        } else {
          location.replace(
            "https://" +
              window.location.host +
              `/guardian?token=${loginUserToken}&userId=${loginUserId}&family_id_check=${famSelected}&setup_page=true`
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.main-top-nav {
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  z-index: 100000;
  padding: 15px;
}

#guardian-login {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 15px;

  &__logo {
    height: 35px;
  }
}

.guardian-login-form {
  padding-top: 1.5em;
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-bottom: auto;

  &__content {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: white;
    border: 1px solid #dedede;
    padding: 20px;
    width: 85%;
  }

  &__input {
    background-color: white;
    width: 100%;
    height: 48px;
    min-height: 48px;
    padding: 15px;
    border: 1px solid rgb(128, 96, 87);
    border-radius: 10px;
    outline: none;
    font-size: 14px;
    color: #444 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__input-block {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  &__label {
    font-family: var(--sans);
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    color: #7a6159;
    width: 85%;

    h1 {
      font-size: 20px;
    }

    p {
      margin-top: 5px;
      font-size: 13px;
    }
  }

  &__button {
    width: 200px;
    min-width: 200px;
    margin: 0 auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--main);
    background: var(--main);
    color: white;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 100ms ease-in-out;
    text-decoration: none;

    &:active {
      transform: scale(0.99);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus {
      background-color: var(--main);
    }
  }

  &__forgot {
    text-align: center;
    font-size: 1rem;
    color: var(--main);
    display: block;
    margin-top: 20px;
  }

  &__register {
    margin-top: 25px;
    text-align: center;
    width: 85%;
    color: #777;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 5px;
    }
  }
}

.register-block{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 1.5em;
}


::placeholder {
  color: #bebebe;
}

.mt {
  margin-top: 10px;
}
</style>
